import React, { Fragment, useState } from 'react'
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { useCookies } from 'react-cookie'
import Loading from '../../../../components/elements/Loading';
import LoadingError from '../../../../components/elements/LoadingError'
import Errorpage from '../../../../layouts/Errorpage'
import Datetime from "../../../../components/functions/Datetime";
import { GrEdit } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

const queryClient = new QueryClient()


export default function Withdrawn(props){
  return(
    <QueryClientProvider client={queryClient}>
      <App role={props.role}/>
    </QueryClientProvider>
  )
}

function App(props) {
  const [cookies] = useCookies([]);
  const [visible, setVisible] = useState(7);
  const { isLoading, error, data } = useQuery(['homeData'], () => 
    fetch(process.env.REACT_APP_BACKEND_URL + '/content/author-withdrawn-posts', {
        credentials: 'include',
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': cookies['plt']
        }
    }).then(res => 
      res.json()
      ),
      {
        retry: false,
        refetchOnWindowFocus: false
      }
    )
    if (isLoading) return <Loading />
    if (error) return <LoadingError />
    if (props.role === 'P') return <Errorpage />

  const onLoadMoreClick = () => {
    setVisible(v => v+6);
  }
  return (
    <>
   <div className="max-w-full w-full mx-auto sm:px-6 lg:px-8 space-y-3">
          <section className="text-gray-100">
            {data.posts.length === 0 && 
            <>
            <div className="text-gray-700 text-center">
              <p>No Deleted Posts yet!</p>
            </div>
            </>}

          {data.posts.length > 0 &&(
          <div className="container max-w-full w-full p-6 mx-auto space-y-2">
            
            
              {data.posts.slice(0, visible).map((post, idx) => (
              <div key={idx} className="flex flex-col max-w-full w-full p-6 space-y-2 overflow-hidden border-2 border-gray-100">
                <div className="flex space-x-4 text-sm text-green-600">
                    <p>Created</p>
                    <p>{post.created_at}</p>
                </div>
                <div>
                    <Link to={'/phis/edit-post/' + post.slug}><h2 className="mb-1 text-xl text-blue-700 font-semibold">{post.title}</h2></Link>
                    <p className="text-sm text-black"><ReactMarkdown children={post.body.slice(0,200)} />...</p>
                </div>
                <div className="flex flex-wrap justify-between text-blue-600">
                    <div className="flex space-x-2 text-sm ">
                        <Link to={'/phis/edit-post/' + post.slug} className="flex items-center p-1 space-x-1.5 text-blue-700 hover:text-blue-900">
                            <GrEdit />
                            <span>Edit</span>
                        </Link>
                    </div>
                </div>
            </div>
              ))}
            {visible < data.posts.length && <div className="flex justify-center">
              <button onClick={onLoadMoreClick} type="button" className="px-6 py-3 text-sm rounded-md hover:underline bg-blue-900 text-white">Load more posts...</button>
            </div>}
            {data.posts.length > 0 && visible > data.posts.length && 
            <div className="flex justify-center text-blue-900">
              <p><em>No more posts</em></p>
            </div>}
          </div>)}
        </section>
        </div>
        </>
  )
}