import { Alert, Slide, Snackbar, TextField } from "@mui/material";
import { useFormik } from "formik"
import { useState } from "react"
import { useCookies } from "react-cookie";
import { AiFillWarning } from "react-icons/ai"
import { GrClose } from "react-icons/gr";
import { RiDeleteBin6Line } from 'react-icons/ri'
import * as Yup from 'yup';

const validationSchema = Yup.object({
    comment: Yup
        .string()
        .required('Enter your reason for deleting this post')
        .min(50, 'Minimum of 50 characters')
        .max(1000, 'Maximum of 1000 characters')   
})

function WithdrawButton(props){
    const [cookies] = useCookies([])
    const [modal, setShowModal] = useState(false)
    const [show, setShow] = useState(false)
    const [warn, setError] = useState(false)

    const formik = useFormik({
        initialValues:{
            comment: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            values['filter'] = props.filter
            fetch(process.env.REACT_APP_BACKEND_URL + '/content/withdraw-post/'+props.post_id, {
                credentials: 'include',
                method: 'PATCH',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': cookies['plt']
                },
                body: JSON.stringify(values, null, 2)
            })
            .then(response => response.json())
            .then(function(data){
                if (data.ok){
                    setShowModal(false)
                    setShow(true)
                }
                else{
                    setError(true)
                }
            })
            .catch(function(error){
                setError(true)
            })
        }
    })
    function TransitionDown(props){
        return <Slide {...props} direction="down" />;
    }
    return (
        <>
        <Snackbar 
            TransitionComponent={TransitionDown}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }} 
            open={show} autoHideDuration={5000} onClose={() => {setShow(false)}}>
            <Alert icon={false} onClose={() => {setShow(false)}} severity="success" sx={{ width: '100%', color: 'white', backgroundColor: '#1e3a8a' }}>
            Post Deleted
            </Alert>
        </Snackbar>
        <Snackbar
            TransitionComponent={TransitionDown}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}  
            open={warn} autoHideDuration={5000} onClose={() => {setError(false)}}>
            <Alert icon={false} onClose={() => {setError(false)}} severity="success" sx={{ width: '100%', color: 'white', backgroundColor: '#ff0000' }}>
            Error
            </Alert>
        </Snackbar>
        {modal &&
        //add this code to this very first div:fixed inset-0 z-10*

        <div className="overflow-y-auto fixed inset-0 z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min- px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                {/*This is the background that overlays when the modal is active. It  has opacity, and that's why the background looks gray.
                add this code to this very first div:
                fixed inset-0
                */}
                <div className="transition-opacity bg-black bg-opacity-25 fixed inset-0" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:" aria-hidden="true">​</span>
                {/* Modal panel : This is where you put the pop-up's content, the div on top this coment is the wrapper */}
                <div
                    className="inline-block p-2 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-2xl lg:p-10 sm:my-8 sm:align-middle sm:max-w-xl sm:w-full border border-blue-100"
                    role="alert"
                    >
                    <div className="flex w-full justify-end">
                        <div>
                            <button
                                type="button"
                                onClick={() => {setShowModal(false)}}
                                className="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center  text-black"
                                >
                                <GrClose className="font-medium text-lg"/>
                                </button>
                        </div>
                    </div>
                    <div className="items-center sm:flex">
                        <span
                        className="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-red-600 text-white"
                        >
                        <AiFillWarning />
                        </span>

                        <p className="mt-3 text-xl font-bold text-red-600 sm:mt-0 sm:ml-3">
                        Delete
                        </p>
                        
                    </div>

                    <p className="mt-4 text-gray-500">
                        You are about to delete <em className="font-bold">{props.title}</em><br /> please tell us why. 
                    </p>
                    
                    <form onSubmit={formik.handleSubmit} method="post" id="revue-form" name="revue-form" target="_blank" className="p-2 mt-2 transition duration-500 ease-in-out transform border2 bg-gray-50 md:mx-auto rounded-xl sm:max-w-lg sm:flex sm:flex-col">
                        <div className="flex-1 min-w-0 revue-form-group">
                        <TextField
                            fullWidth
                            id="comment"
                            label="Comment"
                            name="comment"
                            type="text"
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            variant="standard"
                            multiline
                            maxRows={6}
                            helperText={formik.touched.comment && formik.errors.comment}
                            
                        />
                        </div>
                    <div className="mt-6 sm:flex">
                        <button
                        type="submit"
                        className="py-2 px-4 w-full  bg-red-600 hover:bg-red-500 focus:ring-red-600 focus:ring-offset-red-200 text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-r-lg rounded-l-lg "
                        href=""
                        >
                        Delete
                        </button>
                    </div>
                    </form>
                    <p className="text-gray-500 text-sm">Once deleted, you can find this post in your profile under the deleted tab.</p>
                </div>
            </div>
        </div>}
        <p type="button" onClick={() => {setShowModal(true)}} className="flex items-center p-1 space-x-1.5 text-blue-700 hover:text-blue-900">
            <RiDeleteBin6Line />
        </p>
        </>
    )
}

export default WithdrawButton