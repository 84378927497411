import DocumentMeta from "react-document-meta";

import rocket from "../../assets/img/moon.png"
import DevNavbar from "./components/DevNavbar"

const meta = {
  title: "Developer",
  description: "build & ship with fedgen",
  canonical: "https://fedgen.net/developer",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "PHIS,public,health"
    }
  }
};
function DevLanding (){
    return (
      <>
      <div>
          <DocumentMeta {...meta} />
        </div>
      <DevNavbar />
        <div className="relative overflow-hidden bg-blue-50">
      
        <div className="hidden sm:block sm:relative sm:inset-0" aria-hidden="true">
          <svg
            className="absolute top-3 right-3 transform translate-x-1/2 mb-48 text-blue-300 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
            width={364}
            height={384}
            viewBox="0 0 364 384"
            fill="none"
          >
            <defs>
              <pattern
                id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} fill="currentColor" />
              </pattern>
            </defs>
            <rect width={364} height={384} fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
          </svg>
        </div>
        <div className="relative  pb-16 sm:pb-24">
        
  
          <main className="mt-16 sm:mt-24">
            <div className="mx-auto w-full">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                  <div>
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-900 sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-8xl">
                      
                      build & ship to the moon
                    </h1>
                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      A Federated Genomic (FEDGEN) cloud infrastructure (with in-memory computing and 
                      cloud AI capabilities) towards an informatics based genomic research in Africa.
                    </p>
                    <button type="submit" className="p-3 mt-2 text-sm font-bold tracking-wide shadow-sm rounded-full bg-blue-900 text-white disabled:opacity-75">Get Started</button>
                  </div>
                </div>
                <div className="hidden lg:block mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                  <div className="h-auto sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                    <img src={rocket} alt="rocket" className="object-contain "/>
                 </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      </>
    )
}

export default DevLanding