import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import {TextField } from "@mui/material";
import * as Yup from "yup";
import axios from "axios"

import logo from "../../../assets/img/logo.png"
import DocumentMeta from 'react-document-meta'
import SmallNotification from "../../../components/Notification/SmallNotification";


const meta = {
  title: "Signup",
  description: "Create your account",
  canonical: "https://fedgen.net/auth/signup",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "phis,public,health,information"
    }
  }
};

const SignupSchema = Yup.object().shape({
    email: Yup.string().lowercase()
    .matches(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/, "Invalid Email address")
    .required('Email is required'),
    password: Yup
    .string()
    .min(8, "Password must contain 8 characters")
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/, 'Password must contain: 1 number, 1 special character, 1 Uppercase letter')
    .required('Password is required'),
    password1: Yup.string()
    .required("Field is required")
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    
})
const DevSignup = () => {
    const [redirect, setRedirect] = useState(false)
    const [error, setError] = useState('')
    const [isemail, setEmail] = useState(false)
    const [iseerror, setEmailError] = useState('')
    const [fetcherror, setFError] = useState('')
    const [show, setShow] = useState(false)
    const [eshow, setEShow] = useState(false)
    const email = (value) => {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
               axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/query.email', {
                   "email": value,
               })
               .then(function (response) {
                   if (response.data.ok) {
                        setEmail(true)
                        setEmailError("Email already exists")
                        setEShow(true)
                   }else{
                       setEShow(false)
                       setEmailError("")
                       setEmail(false)
                   }
               })
               .catch(function (error) {
                   setError('server error')
                   setEShow(true)
               });
         }
    }
    
    function handleSubmit(values,  { setSubmitting }){
        fetch('http://localhost:8000/developer/auth/signup', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values, null, 2)
        })
        .then(response => response.json())
        .then((data) => {
            if (data.ok){
                setSubmitting(false)
                setRedirect(true)
            }else{
                setSubmitting(false)
                setFError("Something wrong happened, Please try again")
                setShow(true)
                
            }
        })
        .catch(function(error){
            setFError("Server Error")
            setShow(true)
            setSubmitting(false)
        })
    }

    if(redirect){
        return <Navigate to='/auth/success' />
    }
    return (
        <>
        <div>
          <DocumentMeta {...meta} />
        </div>
        {show &&
        <button className="top-0 w-full" onClick={() => {setShow(false)}}>
            <SmallNotification  status="error" message="Error" detail={fetcherror} />
        </button>}
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg shadow-lg p-6 m-4 bg-gray-100 border-0 md:max-w-xl md:mx-auto">
              <div >
                <div className='flex flex-col justify-center items-center'>
                <a
                    href="/developer"
                    className="h-20 w-auto"
                  >
                    <img src={logo} alt="ace" />        
                  </a>
                  </div>
                  <h2 className="font-sans font-bold text-blue-900 mx-auto text-center text-2xl">Create your Developer account</h2>
                  <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        password1: '',}
                    }
                    validationSchema={SignupSchema}
                    onSubmit={handleSubmit}
                    >
                  {props => (<form onSubmit={props.handleSubmit} className="mt-2 space-y-6">
                    <div>
                        <TextField
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            type="text"
                            variant="outlined"
                            required
                            value={props.values.email}
                            onChange={props.handleChange}
                            error={props.touched.email && (email(props.values.email) || isemail  || Boolean(props.errors.email))}
                            helperText={props.touched.email && (props.errors.email || iseerror)}
                            />
                        {/*eshow && <p className="text-sm font-light text-red-600 ml-3">Email is not avaible</p>*/}
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            variant="outlined"
                            required
                            value={props.values.password}
                            onChange={props.handleChange}
                            error={props.touched.password && Boolean(props.errors.password)}
                            helperText={props.touched.password && props.errors.password}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            id="password1"
                            label="Confirm Password"
                            name="password1"
                            type="password"
                            variant="outlined"
                            required
                            value={props.values.password1}
                            onChange={props.handleChange}
                            error={props.touched.password1 && Boolean(props.errors.password1)}
                            helperText={props.touched.password1 && props.errors.password1}
                        />
                    </div>
                    <div className="flex">
                        
                    <button type="submit" 
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
                            >
                                {!props.isSubmitting && "Signup"}
                                {props.isSubmitting && 
                                <p className="animate-pulse">Loading...</p>}
                            </button>
                    
                    </div>
                </form>)}
                </Formik>
                <div className="text-center text-sm ">
                  <hr className="mt-6 mb-6 border-blue-800"></hr>
                  <span>already have an account?</span>
                  <div className="mt-3 text-sm">
                   <a href="/developer/auth/signin"><span className="font-medium text-sm text-blue-900 hover:text-blue-600">Signin</span></a>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      </>
    )
}
export default DevSignup