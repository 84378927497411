import React from 'react'
import logo from "../../../assets/img/logo.png"
import DocumentMeta from 'react-document-meta'
import DevInputEmail from '../components/DevInputEmail';

const meta = {
  title: "Forgot Password",
  description: "Enter your email",
  canonical: "https://fedgen.net/auth/enteremail",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "phis,public,health,information"
    }
  }
};

const DevResetPassword = () => {
    return (
        <>
        <div>
          <DocumentMeta {...meta} />
        </div>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg shadow-lg p-6 m-4 bg-gray-100 border-0 md:max-w-sm md:mx-auto">
              <div >
              <div className='flex flex-col justify-center items-center'>
                <a
                  href="/developer"
                  className="h-20 w-auto"
                >
                  <img src={logo} alt="" className='h-12 w-12' />        
                </a>
                </div>
                <h2 className="font-sans font-bold text-xl text-blue-900 mx-auto text-center ">Reset Password</h2>
            
                
                <DevInputEmail link={process.env.REACT_APP_BACKEND_URL + "/developer/get.password.link"} pwd={true}/>
                
                
              </div>
          </div>
        </div>
      </div>
      </>
    )
}
export default DevResetPassword