import React, { Fragment, useState } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import {
  ChatAltIcon,
  CodeIcon,
  DotsVerticalIcon,
  EyeIcon,
  FlagIcon,
  PlusIcon,
  SearchIcon,
  ShareIcon,
  StarIcon,
  ThumbUpIcon,
} from '@heroicons/react/solid'
import { BellIcon, FireIcon, HomeIcon, MenuIcon, TrendingUpIcon, UserGroupIcon, XIcon } from '@heroicons/react/outline'

import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { Chip } from '@mui/material'
import { useCookies } from 'react-cookie'
import Loading from '../../../../components/elements/Loading'
import Errorpage from '../../../../layouts/Errorpage'
import MouseOverPopover from '../../../../components/elements/MouseOverPopOver'
import Datetime from '../../../../components/functions/Datetime'
import { CgSandClock } from 'react-icons/cg'
import ReactMarkdown from 'react-markdown'
import DjangoDateTime from '../../../../components/functions/DjangoDateTime'

const queryClient = new QueryClient()


export default function AuthorSubmitted(props){
  return(
    <QueryClientProvider client={queryClient}>
      <App user={props.data} role={props.role}/>
    </QueryClientProvider>
  )
}

function App(props) {
  const [cookies] = useCookies([]);
  const [visible, setVisible] = useState(7)
  const { isLoading, error, data } = useQuery(['pendingData'], () => 
    fetch(process.env.REACT_APP_BACKEND_URL + '/content/get-author-submitted-drafts', {
        credentials: 'include',
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': cookies['plt']
        }
    }).then(res => 
      res.json()
      ),
      {
        retry: false,
        refetchOnWindowFocus: false
      }
    )
    if (isLoading) return <Loading />
    if (error) return 'An error has occured: ' + error.message
    if (props.role === 'P') return <Errorpage />  
    const onLoadMoreClick = () => {
      setVisible(v => v+6);
    }
  return (
    <>
    <div className="max-w-full w-full mx-auto sm:px-6 lg:px-8 space-y-3">
          <section className="text-gray-100">
            {data.posts.length === 0 && 
            <>
            <div className="text-gray-700 text-center">
              <p>No Posts yet. Write something!</p>
            </div>
            </>}

          {data.posts.length > 0 &&(
          <div className="container max-w-full w-full p-6 mx-auto space-y-2">
            
            
              {data.posts.slice(0, visible).map((post, index) => (
              <div className="flex flex-col max-w-full w-full p-6 space-y-2 overflow-hidden border-2 border-gray-100">
                <div className="flex space-x-4">
                    <div className="flex flex-row text-green-600 justify-center items-center space-x-1">
                        
                        <span className="text-xs">{post.created_at}</span>
                    </div>
                </div>
                <div>
                    <h2 className="mb-1 text-xl text-blue-700 font-semibold">{post.title}</h2>
                    <p className="text-sm text-black"><ReactMarkdown children={post.body.slice(0, 200) + "..."} /></p>
                </div>
                <div className="flex flex-wrap justify-between text-blue-600">
                    
                    <div className="flex space-x-2 text-sm ">
                        <button type="button" className="flex items-center p-1 space-x-1.5 text-yellow-700 hover:text-yellow-900">
                            <CgSandClock />
                            Your post is pending approval
                        </button>
                    </div>
                </div>
            </div>
              ))}
            {visible < data.posts.length && <div className="flex justify-center">
              <button onClick={onLoadMoreClick} type="button" className="px-6 py-3 text-sm rounded-md hover:underline bg-blue-900 text-white">Load more posts...</button>
            </div>}
            {data.posts.length > 0 && visible > data.posts.length && 
            <div className="flex justify-center text-blue-900">
              <p><em>No more posts</em></p>
            </div>}
          </div>)}
        </section>
        </div>
    </>
  )
}