import React, { useState } from "react"
import { useParams } from "react-router"
import { Navigate } from "react-router-dom";
import { useFormik  } from "formik";
import { useJwt } from "react-jwt"
import DocumentMeta from "react-document-meta"
import * as Yup from 'yup';
import axios from "axios"

import logo from "../../../assets/img/logo.png"
import SmallNotification from "../../../components/Notification/SmallNotification";
import { TextField } from "@mui/material";

const meta = {
    title: "Forgot Password | Developer",
    description: "Get a new one",
    canonical: "https://fedgen.net/developer/auth/forgot-password",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "phis,public,health,information"
      }
    }
  };

const validationSchema = Yup.object({
    password: Yup
        .string()
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/, "Password must contain at least: \n 1 Number \n 1 Special Character \n 1 Uppercase Letter")
        .min(8, "Password must be a minimum of 8 characters")
        .required("Password is required"),
    password1: Yup
    .string()
    .required("Password is required")
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

const DevForgotPassword = () => {
    const {id} = useParams()
    const [redirect, setRedirect] = useState(false)
    const [error, setError] = useState(false)
    const [servererror, setSError] = useState(false)
    const [show, setShow ] = useState(false)
    const { isExpired } = useJwt(id)
    const formik = useFormik({
        initialValues: {
            password: '',
            password1: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/forgot.password', {
                'password': values['password'],
                'id': id
            })
            .then(function(response){
                if (response.data.ok){
                    setShow(true)
                    setRedirect(true)
                }else {
                    setError(true)
                }
            })
            .catch(function(error){
                setSError(true)
            })
        }
    });

    

    if(isExpired){
        return <Navigate to={'/expired'} />
    }    
    if(redirect){
        return <Navigate to={'/developer/auth/signin'} />
    }
    if(error){
        return <Navigate to={'/error'} />
    }

    return (
        <>
        <div>
            <DocumentMeta {...meta} />
        </div>
        { show === true &&
        <button onClick={() => {
            setShow(false)
          }}>
          <SmallNotification status="ok" message="Success! Password changed" detail="Please wait...Redirecting" />  
        </button>
         } 
         { servererror === true &&
        <button onClick={() => {
            setSError(false)
          }}>
          <SmallNotification status="error" message="Error" detail="Server Error" />  
        </button>
         }
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg shadow-lg p-6 m-4 bg-gray-100 border-0 md:max-w-sm md:mx-auto">
                <div>
                <div className='flex flex-col justify-center items-center'>
                    <a
                    href="/developer"
                    className="h-20 w-auto "
                    >
                    <img src={logo} alt="" />        
                    </a>
                    </div>
                    <h2 className="font-sans font-medium text-xl mt-5 text-blue-600 mx-auto text-center ">Enter new Password</h2>
                    <form onSubmit={formik.handleSubmit} className="mt-2 space-y-6">
                    <div className="space-y-2">
                        <div> 
                            <TextField
                                fullWidth
                                id="password"
                                label="New password"
                                name="password"
                                type="password"
                                variant="outlined"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                id="password1"
                                label="Confirm Password"
                                name="password1"
                                type="password"
                                variant="outlined"
                                required
                                value={formik.values.password1}
                                onChange={formik.handleChange}
                                error={formik.touched.password1 && Boolean(formik.errors.password1)}
                                helperText={formik.touched.password1 && formik.errors.password1}
                            />
                        </div>
                    </div>
                    <button type="submit" 
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
                    >
                        Submit
                    </button>
                </form>
                    
                </div>

            </div>
            </div>
        </div>
        </>
    )

}
export default DevForgotPassword