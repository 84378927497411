import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Fallback from "../../components/elements/Fallback";
import DevForgotPassword from "./views/DevForgotPassword";
import DevGenerateVerifyLink from "./views/DevGenerateVerifyLink";
import DevResetPassword from "./views/DevResetPassword";
import DevSignin from "./views/DevSignin";
import DevSignup from "./views/DevSignup";
import DevSuccessPage from "./views/DevSuccessPage";
import DevVerifyEmail from "./views/DevVerifyEmail";

//components

const FooterSmall = lazy(() => import("../../components/Footer/FooterSmall"));

const Errorpage = lazy(() => import("../../layouts/Errorpage"));


export default function DevAuth(){
    return (
        <>
        <Suspense fallback={<Fallback />}>
            <main>
                <section className="relative w-full h-full py-40 min-h-screen">
                    <div
                        className="absolute top-0 w-full h-full md:h-full"
                        style={{
                            backgroundImage:"url(/bg-dev.png)",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat"
                            }}
                    ></div>
                        <Routes>
                            <Route path="signin" element={<DevSignin />} />
                            <Route path="signup" element={<DevSignup />} />
                            <Route path="verify/:id" element={<DevVerifyEmail />} />
                            <Route path="reset-password" element={<DevResetPassword />} />
                            <Route path="forgot-password/:id" element={<DevForgotPassword />} />
                            <Route path="generate-verify-link" element={<DevGenerateVerifyLink />} />
                            <Route path="success" element={<DevSuccessPage />} />
                            <Route path="*"  element={<Errorpage />} />
                            {/*<Redirect from="/auth" to="/auth/signin" /> */}
                        </Routes>
                    <FooterSmall  />
                </section>
            </main>
        </Suspense>
        </>
    )
}