import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import axios from "axios"
import SmallNotification from "../../../components/Notification/SmallNotification";


const DevInputEmail = (props) => {
    const [redirect, setRedirect] = useState(false)
    const [error, setError] = useState('')
    
    const [warning, setWarning] = useState('')
    const [False, setFalse] = useState(false)
    

    const [success, setSuccess] = useState('')
    const [show, setShow ] = useState(false)
   
    function email(value){
        if (!value) {
            setError('*Required');
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            setError('Invalid email address');
          }else {
            setError('')
          }
         return error;

    }
    function handleSubmit (values, {setSubmitting}) {
        fetch(process.env.REACT_APP_BACKEND_URL + '/developer/query.email', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values, null, 2)
        })
        .then(function (response){
            response.json().then(data => {
                if (data.ok && data.verified && props.pwd){
                    axios.post(props.link, {
                        "email": values['email']
                    }).then(function (response){
                        if (response.data.ok){
                            setSubmitting(false)
                            setSuccess(response.data.message)
                            setRedirect(true)
                            setShow(true)
                            
                            
                        }
                        else {
                            setSubmitting(false)
                            setWarning(response.data.message)
                            setFalse(true)
                        }
                    })
                    .catch((error) => {
                        setSubmitting(false)
                        setWarning('Server Error Try again')
                        setFalse(true)
                        
                    }) 
                }
                else if (data.ok && !data.verified && !props.pwd) {
                    axios.patch(props.link, {
                        "email": values['email']
                    }).then(function (response){
                        if (response.data.ok){
                            setSubmitting(false)
                            setSuccess(response.data.message)
                            setRedirect(true)
                            setShow(true)
                        }
                        else {
                            setSubmitting(false)
                            setWarning(response.data.message)
                            setFalse(true)
                        }
                    })
                    .catch((error) => {
                        setSubmitting(false)
                        setWarning('Server Error Try again')
                        setFalse(true)
                        
                    }) 
                }
                else if (data.ok && data.verified && !props.pwd) {
                    setSubmitting(false)
                    setWarning(data.message)
                    setFalse(true)
                }
                else if (data.ok && !data.verified && props.pwd) {
                    setSubmitting(false)
                    setWarning(data.message)
                    setFalse(true)
                }
                else if (!data.ok){
                    setSubmitting(false)
                    setWarning(data.message)
                    setFalse(true)
                }
            })
        })
        .catch((error) => {
            setSubmitting(false)
            setWarning('Server Error')
            setFalse(true)
            
        })  
    }
    
    
    return (
        <>
        { False && 
        <a onClick={() => {
            setFalse(false)
          }}>
          <SmallNotification status="error" message="Error" detail={warning} />  
        </a>
         }
         
        <Formik
            initialValues={{
                email: '',
            }}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, errors, touched }) => (
                
                <Form className="mt-3 space-y-6">
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email" className="sr-only">Email</label>
                            <Field name="email" placeholder="Email address" 
                            className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-900 focus:border-blue-900 focus:z-10 sm:text-sm"
                            autoComplete="email"
                            validate={email}
                            />
                                {errors.email && touched.email &&
                                    <div className="text-red-500 font-sans text-xs mb-2">
                                        {errors.email}
                                        </div>
                                }
                                { show && Boolean(success) &&
                                <a onClick={() => {
                                    setShow(false)
                                }}>
                                <SmallNotification status="ok" message="Success" detail={success} link="/" link_text="Go Home" />  
                                </a>
                                }
                        </div>
                    </div>
                    <button type="submit" 
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    disabled={isSubmitting}
                    >
                        {!isSubmitting && "Submit"}
                        {isSubmitting && <p className="animate-pulse">Loading..</p>}
                    </button>
                </Form>
            )}
        </Formik>
        </>
    )
}
export default DevInputEmail