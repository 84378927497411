import DevMenuBar from "./components/DevMenuBar";
import DevNavbar from "./components/DevNavbar";
import DevSidebar from "./components/DevSidebar";

function DevDocs(){
    return (
        <>
        <DevNavbar />
        <DevMenuBar />
        <DevSidebar />
        </>
    )
}

export default DevDocs