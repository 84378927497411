import axios from "axios"
import React from "react"
import { useState } from "react"
import { useParams } from "react-router"
import { Navigate } from 'react-router-dom'
import { useJwt } from "react-jwt"
import logo from "../../../assets/img/logo.png"
import SmallNotification from "../../../components/Notification/SmallNotification"
const DevVerifyEmail = () => {
    const [ redirect, setRedirect ] = useState(false)
    const [ show, setShow ] = useState(false)
    const [ errorredirect, setErrorRedirect ] = useState(false)
    const {id} = useParams()
    const { isExpired } = useJwt(id)
    if (id){
        axios.post(process.env.REACT_APP_BACKEND_URL + '/developer/verify.email', {
            "token": id
        })
        .then(function(response){
            if (response.data.ok === true){
                setRedirect(true)
            }else{
                setShow(true)
            }
        })
        .catch((error) => {
            setErrorRedirect(true)
        })
    }
    if (redirect){
        return <Navigate to='/auth/signin' />
    }
    if (errorredirect){
        return <Navigate to='/servererror' />
    }
    if (isExpired){
        return <Navigate to='/expired' />
    }
    
    return (
        <>
        { show === true &&
        <button onClick={() => {
            setShow(false)
          }}>
          <SmallNotification status="error" message="Invalid Link" detail="Your account has already been Verified" />  
        </button>
         }
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg shadow-lg p-6 m-4 bg-gray-100 border-0 md:max-w-sm md:mx-auto">
                <div>
                    <a
                        href="/"
                        className="h-15 w-full flex justify-center items-center py-2 px-4 "
                    >
                        <img src={logo} alt="" />        
                    </a>
                    <p className="text-medium text-center">Please wait while your email is Verified</p>
                    
                </div>

            </div>
            </div>
        </div>
        </>
    )

}
export default DevVerifyEmail