import React, { useState } from 'react'
import logo from "../../../assets/img/logo.png"
import DocumentMeta from 'react-document-meta'
import { Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { Formik, Field, Form } from "formik";
import { useJwt } from "react-jwt"
import { useCookies } from "react-cookie";
import SmallNotification from '../../../components/Notification/SmallNotification';
const meta = {
  title: "Signin | Developer",
  description: "Enter your developer account",
  canonical: "https://fedgen.net/developer/auth/signin",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "PHIS,public,health"
    }
  }
};

const DevSignin = () => {
    let location = useLocation()

    let from = location.state?.from?.pathname || "/phis";
    
    const [redirect, setRedirect] = useState(false)
    const [error, setError] = useState('')
    const [show, setShow ] = useState(false)
    const [ver, setVer ] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [token, setToken] = useState('')
    const [warning, setWarning] = useState('')
    const [ cookies, setCookie ] = useCookies(['_user']);
    const { decodedToken, isExpired } = useJwt(cookies['plt']);
    function email(value){
        if (!value) {
            setError('*Required');
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            setError('Invalid email address');
          }else {
            setError('')
          }
         return error;

    }
    if (!isExpired && cookies['plt']){
        return <Navigate to='/phis' />
    }
    if(redirect){
        return <Navigate to={from} replace={true} />
    }
    function handleSubmit (values, { setSubmitting }) {
        fetch('https://localhost:8000/developer/signin', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values, null, 2)
        })
        .then(function (response){
            if (response.ok){
                response.json().then(data => {
                    if (data.ok){
                        setToken(data.token)
                        if (!cookies['plt']){
                            setSubmitting(false)
                            setCookie('plt', data.token, { 'path': '/', 'maxAge': 3600});
                            setRedirect(true)
                        }else if (isExpired){
                            setSubmitting(false)
                            setWarning("Invalid token")
                            setShow(true)
                        }
                    }
                    else if (data.message === "NotVerified"){
                        setVer(true)
                        setSubmitting(false)
                        setShow(true)
                    }
                    else if (!data.ok && data.message === "Incorrect password") {
                        setSubmitting(false)
                        setShow(true)
                    }
                    else if (!data.ok){
                        setSubmitting(false)
                        setWarning(data.message)
                        setShow(true)
                    }   
                })
            }  
        })
        .catch(function (error){
            setSubmitting(false)
            setWarning("Server is unavailable. Please try again later.")
            setShow(true)
        }) 
    }    
    return (
        <>
        <div>
          <DocumentMeta {...meta} />
        </div>
        { show && ver &&
        <a onClick={() => {
            setShow(false)
            setVer(false)
          }}>
          <SmallNotification status="error" message="Invalid Login" detail="Your email has not been verified. Check your inbox for a link or get a new link" link="/auth/veremail" link_text="Get Link"/>  
        </a>
         }
        { show && !ver &&
        <button onClick={() => {
            setShow(false)
          }}>
          <SmallNotification status="error" message="Invalid Login" detail="Incorrect email or password" />  
        </button>
         }
         { show && !ver && Boolean(warning) &&
        <button onClick={() => {
            setShow(false)
          }}>
          <SmallNotification status="error" message="Error" detail={warning} />  
        </button>
         }
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg shadow-lg p-6 m-4 bg-gray-100 border-0 md:max-w-sm md:mx-auto">
              <div >
              <div className='flex flex-col justify-center items-center'>
                <a
                  href="/developer"
                  className="h-20 w-auto "
                >
                  <img src={logo} alt="" />        
                </a>
                </div>
                <h2 className="font-sans font-bold text-blue-900 mx-auto text-center text-3xl">Developer Sign In</h2>
            
                {/*Signin form */}
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    
                    onSubmit={handleSubmit}
                >
            
            {({ isSubmitting, errors, touched }) => (
                
                <Form className="mt-8 space-y-6">
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email" className="sr-only">Email</label>
                            <Field name="email" placeholder="Email address" 
                            className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-800 focus:border-blue-800 focus:z-10 sm:text-sm"
                            autoComplete="email"
                            validate={email}
                            />
                                {errors.email && touched.email &&
                                    <div className="text-red-500 font-sans text-xs mb-2">
                                        {errors.email}
                                        </div>
                                }
                            
                        </div>
                        <div> 
                            <label htmlFor="password" className="sr-only">Password</label>
                            <Field name="password" placeholder="Password" type="password"
                            className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-800 focus:border-blue-800 focus:z-10 sm:text-sm"
                            required
                            autoComplete="current-password"
                            />
                        </div>
                        
                    </div>
                    <button 
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
                        disabled={isSubmitting}>
                        {!isSubmitting && "Signin"}
                        {isSubmitting && <p className="animate-pulse">Loading..</p>}
                    </button>
                </Form>
            )}
        </Formik>
               

                <div className="mt-3 text-center text-sm">
                    <a href="/developer/auth/reset-password"><span className="font-medium text-sm text-blue-900 hover:text-blue-600">Forgot password?</span></a>
                  </div>
                <div className="text-center text-sm ">
                  <hr className="mt-6 mb-6 border-blue-800"></hr>
                  <span>Don't have an account?</span>
                  <div className="mt-3 text-sm">
                    <a href="/developer/auth/signup"><span className="font-medium text-sm text-blue-900 hover:text-blue-600">Sign Up</span></a>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      </>
    )
}
export default DevSignin